<template>
    <div>
        <div v-if="!access">
            <NotFound />
        </div>
        <div v-else>
            <div class="pb-2 mb-3 border-bottom">
                <h2>App Settings</h2>
            </div>
            <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">App Settings</li>
            </ol>
            </nav>
            <br />
            <b-container fluid>
                <div v-if="api_error">
                    <b-alert
                        :show="alertDismissCountDown"
                        dismissible
                        variant="danger"
                        @dismissed="alertDismissCountDown=0"
                        @dismiss-count-down="alertCountDownChanged"
                    >{{api_error}}</b-alert>
                </div>
                <div v-if="api_success">
                    <b-alert
                        :show="alertDismissCountDown"
                        dismissible
                        variant="success"
                        @dismissed="alertDismissCountDown=0"
                        @dismiss-count-down="alertCountDownChanged"
                    >{{api_success}}</b-alert>
                </div>
                <div class="col-md-10" v-if="app_settings_fields">
                    <div
                        no-body
                        class="mb-1"
                        v-for="(group,  group_name) in app_settings_fields"
                        :key=" group_name"
                    >
                        <div class="row col-md-12">
                            <div class="col-md-11">
                                <h5>{{str_title(group_name)}}</h5>
                            </div>
                            <div class="col-md-1">
                                <b-button variant="outline-secondary" v-b-toggle="'toggle-'+group_name">
                                    <span class="when-open">Collapse</span><span class="when-closed">Expand</span>
                                </b-button>
                                
                            </div>
                        </div>
                            <b-collapse :id="'toggle-'+group_name">
                                <b-card-body>
                                    <div class="form-group row" v-for="(field, index) in group" :key="index">
                                        <label class="col-md-3 text-capitalize">{{field.title}}
                                            <span v-if="isRequired(field.validation)" class="text-danger" style="font-weight:bold;">*</span>
                                        </label>
                                        <div class="col-md-6" v-if="field.type=='text'">
                                            <input type="text"
                                                v-model="settings[field.id]"
                                                class="form-control"
                                                v-on:change="onValueCange($event, field.type, field.id, group_name+ '_' +field.id)"
                                            />
                                        </div>
                                        <div class="col-md-6" v-if="field.type=='radio'">
                                            <span
                                                class="mx-4"
                                                v-for="(radio_option, radio_index) in getFieldOptions(field.validation)"
                                                :key="radio_index"
                                            >
                                                <input
                                                    type="radio"
                                                    v-model="settings[field.id]"
                                                    v-on:change="onValueCange($event, field.type, field.id, group_name+ '_' +field.id)"
                                                    :name="field.name"
                                                    :value="radio_option"
                                                    class="form-check-input"
                                                />
                                                <label class="form-check-label text-capitalize">{{radio_option}}</label>
                                            </span>
                                        </div>
                                        <div class="col-md-6" v-if="field.type=='checkbox'">
                                             <b-form-checkbox-group 
                                                v-on:change="onValueCange($event, field.type, field.id, group_name+ '_' +field.id)"
                                                v-model="settings[field.id]"
                                            >
                                                <b-form-checkbox
                                                    v-for="(option, index) of getFieldOptions(field.validation)" :key="index"
                                                    :value="option" 
                                                    class="text-capitalize"
                                                >
                                                    {{option}}
                                                </b-form-checkbox>
                                            </b-form-checkbox-group>
                                        </div>
                                        <div class="col-md-6" v-if="field.type == 'textarea'">
                                            <textarea class="form-control"
                                                v-model="settings[field.id]"
                                                v-on:change="onValueCange($event, field.type, field.id, group_name+ '_' +field.id)"
                                                rows="4"
                                            >
                                            </textarea>
                                        </div>
                                        <div class="col-md-8" v-if="field.type == 'json'">
                                            <prism-editor
                                                v-model="settings[field.id]"
                                                v-on:change="onValueCange($event, field.type, field.id, group_name+ '_' +field.id)"
                                                language="js"
                                            >
                                            </prism-editor>
                                        </div>
                                        <div class="col-md-6" v-if="field.type=='select'">
                                            <select class="form-control text-capitalize"
                                                v-model="settings[field.id]"
                                                v-on:change="onValueCange($event, field.type, field.id, group_name+ '_' +field.id)"
                                            >
                                                <option value=""> Select One </option>
                                                <option
                                                    v-for="option in getFieldOptions(field.validation)"
                                                    :key="option"
                                                    :value="option"
                                                >{{option}}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-1">
                                            <button type="button" class="btn-primary btn btn-sm" disabled :id="group_name+ '_' +field.id" @click="submitAppSettings(field.id,group_name+ '_' +field.id)">Save</button>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                            <hr/>
                    </div>
                </div>
                <div v-else>
                    <span>
                        <i class="fa fa-spinner" aria-hidden="true"></i>
                    </span>
                </div>
            </b-container>
        </div>
    </div>
</template>
<script>
import NotFound from "../errors/NotFound";
import default_error from "../../assets/js/global";
import "prismjs";
import "prismjs/themes/prism.css";
import PrismEditor from 'vue-prism-editor';
import "vue-prism-editor/dist/VuePrismEditor.css";
export default {
    components: {
        NotFound,
        PrismEditor
    },
    data() {
        return {
            app_settings_fields: null,
            settings: {},
            api_error: "",
            api_success: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            page_loader: false,
            previous_settings: {},
        };
    },
    mounted() {
    this.access = this.hasPermission("CAN_MANAGE_APP_SETTINGS");
    if(this.access){
        this.getAppSettings();
    }
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        isRequired(validation){
            return validation.includes("required");
        },
        getFieldOptions(validation) {
            let array = validation.split('|');
            let options = [];
            array.forEach(data =>{
                if(data.startsWith("in:")) {
                    data = data.split("in:").pop()
                    options = data.split(',');
                }
            });
            return options;
        },
        getAppSettings: function() {
            this.startProgressBar();
            this.page_loader = true;
            let query = this.getAuthHeaders();
            this.axios
                .get(this.$api.get_app_setting_with_fields, query)
                .then(response => {
                    let result = response.data;
                    if (result.error) {
                        this.api_error = response.data.message;
                        this.showAlert();
                        this.updateProgressBar(true);
                        return;
                    }
                    this.app_settings_fields = result.data[0].app_settings_fields;
                    let settings = result.data[0].app_settings;
                    for (let group_name in this.app_settings_fields) {
                        this.app_settings_fields[group_name].forEach(field => {
                            if(settings && Object.prototype.hasOwnProperty.call(settings,group_name)) {
                                settings[group_name].filter(setting => {
                                    if (setting.app_settings_fields.id == field.id){
                                        if (setting.value) {
                                            this.settings[field.id] = setting.value;
                                            this.previous_settings[field.id] = setting.value;
                                            return;
                                        }
                                        return;
                                    }
                                });
                            }
                            if (!Object.prototype.hasOwnProperty.call(this.settings,field.id)){
                                if (field.type == 'checkbox') {
                                    this.settings[field.id] = [];
                                    this.previous_settings[field.id] = [];
                                } else {
                                    this.settings[field.id] = "";
                                    this.previous_settings[field.id] = "";
                                }
                            }
                        });
                    }
            this.page_loader = false;
            this.updateProgressBar(true);
            })
            .catch(err => {
            if (!err.response) {
                this.api_error = default_error.server_error;
            } else if (err.response.data.code == 422) {
                this.api_error = err.response.data.message;
            } else {
                this.api_error = default_error.server_error;
                console.error(err.response.data.message);
            }
            this.showAlert();
            this.page_loader = false;
            this.updateProgressBar(false);
            });
        },
        submitAppSettings: function(field_id,button_id) {
            document.getElementById(button_id).disabled = true;
            let settings = {};
            settings[field_id] = this.settings[field_id];
            this.axios
                .post(this.$api.update_app_settings,{app_settings: settings},this.getAuthHeaders())
                    .then(response => {
                        if (response.data.error) {
                            this.api_error = response.data.message;
                            this.showAlert();
                            this.updateProgressBar(true);
                            return;
                        }
                        this.api_error = "";
                        this.api_success = response.data.message;
                        this.showAlert();
                        window.scrollTo(0, 0);
                        this.getAppSettings();
                        this.updateProgressBar(true);
                    })
                    .catch(err => {
                        if (!err.response) {
                            this.api_error = default_error.server_error;
                        } else if (err.response.data.code == 422) {
                            this.api_error = err.response.data.message;
                        } else {
                            this.api_error = default_error.server_error;
                            console.error(err.response.data.message);
                        }
                        this.api_success = "";
                        this.showAlert();
                        window.scrollTo(0, 0);
                        this.updateProgressBar(false);
                    });
        },
        onValueCange(select, field_type, field_id, button_id) {
            if (field_type === 'checkbox') {
                if (JSON.stringify(this.previous_settings[field_id]) == JSON.stringify(select)) {
                    document.getElementById(button_id).disabled = true;
                } else {
                    document.getElementById(button_id).disabled = false;
                }
            } else {
                if (this.previous_settings[field_id] === this.settings[field_id]) {
                    document.getElementById(button_id).disabled = true;
                } else {
                    document.getElementById(button_id).disabled = false;
                }
            }
        },
    }
};
</script>
<style lang="scss">
pre[class*="language-"] {
    background: #fff !important;
};
.token.string {
    color: #dc3545;
};
.token.number {
    color: #6610f2;
};
.token.punctuation {
    color: #000000;
};
.token.operator {
    color: #000000;
    background: none;
};
.prism-editor-wrapper {
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
};
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>